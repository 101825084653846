import * as React from 'react'
import { useState, useMemo } from 'react'
import filter from 'lodash/filter'
import take from 'lodash/take'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import { SelectMultiple } from '@toasttab/buffet-pui-dropdowns'
import { RestaurantIcon } from '@toasttab/buffet-pui-icons'
import { useDebounceValue } from '../hooks'
import { formatName } from '../helpers'

const exactMatch = (rawVal: string = '', rawQuery: string = '') => {
  const query = rawQuery.trim().toLowerCase()
  if (!query) return false
  const val = rawVal.trim().toLowerCase()
  if (!val) return false
  return query === val
}

const matches = (rawVal: string = '', rawQuery: string = '') => {
  const query = rawQuery.trim().toLowerCase()
  if (!query) return true
  const val = rawVal.trim().toLowerCase()
  if (!val) return false
  const tokens = compact(query.split(' '))
  for (const token of tokens) {
    if (!val.includes(token)) return false
  }
  return true
}

type Props = {
  restaurantNames: string[] | undefined
  selectedRestaurantNames: string[]
  onChange: (list: string[]) => void
}

export const RestaurantSelect = (props: Props) => {
  const { restaurantNames = [], selectedRestaurantNames, onChange } = props

  const [rawSearch, setRawSearch] = useState<string>('')

  const search = useDebounceValue(formatName(rawSearch))

  const exactRestaurantNames = useMemo(() => {
    return filter(restaurantNames, (name) => exactMatch(name, search))
  }, [restaurantNames, search])

  const filteredRestaurantNames = useMemo(() => {
    return filter(restaurantNames, (name) => matches(name, search))
  }, [restaurantNames, search])

  const visibleRestaurantNames = useMemo(() => {
    return uniq([
      ...take(exactRestaurantNames, 100),
      ...take(filteredRestaurantNames, 100),
      ...selectedRestaurantNames
    ])
  }, [exactRestaurantNames, filteredRestaurantNames, selectedRestaurantNames])

  return (
    <div className='pb-4'>
      <SelectMultiple
        enableSearch
        helperText='Search for a restaurant'
        iconLeft={<RestaurantIcon accessibility='decorative' />}
        name='restaurant-select'
        onSearch={(str) => setRawSearch(str)}
        onChange={(value) => onChange(value)}
        options={visibleRestaurantNames}
        value={selectedRestaurantNames}
      />
    </div>
  )
}

import * as React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

type SpitSectionProps = {
  left: React.ReactNode
  right?: React.ReactNode
}

const Split = ({ left, right }: SpitSectionProps) => {
  return (
    <div className='flex flex-row w-full pt-8 pb-8'>
      <div className='basis-1/2 pr-4 w-full'>{left}</div>
      <div className='basis-1/2 pl-4 w-full'>{right || ''}</div>
    </div>
  )
}

type Props = React.PropsWithChildren<{
  title?: React.ReactNode
  isLoading?: boolean
}>

const FormSection = (props: Props) => {
  const { title, isLoading, children } = props

  return (
    <div className='pb-8'>
      <CardContainer>
        {title && <h2>{title}</h2>}

        {isLoading ? <MerryGoRound size='lg' /> : children}
      </CardContainer>
    </div>
  )
}

FormSection.Split = Split

export default FormSection

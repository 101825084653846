import * as React from 'react'
import { useFeature } from '@toasttab/ec-features'
import { Navigate, useParams } from 'react-router-dom'

export const StartImplementationPage = () => {
  const { companyCode } = useParams()

  const hasNewCreatePage = useFeature('ec-platform-create-company-page')

  if (hasNewCreatePage && companyCode) {
    return (
      <Navigate to={`/${companyCode}/implementation/create-company`} replace />
    )
  }

  return null
}

import * as React from 'react'

import { AppProviders } from './AppProviders'
import { AppRouter } from './AppRouter'

export const App = () => (
  <AppProviders>
    <AppRouter />
  </AppProviders>
)

import * as React from 'react'
import { Fragment } from 'react'
import {
  Table,
  Body,
  Head,
  HeadingCell,
  Row,
  Cell
} from '@toasttab/buffet-pui-table'
import without from 'lodash/without'
import isEmpty from 'lodash/isEmpty'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import type { Restaurant } from '../types'

type Props = {
  restaurants: Restaurant[]
  selectedGuids: string[]
  onSelectGuids: (list: string[]) => void
}

export const LocationsTable = (props: Props) => {
  const { restaurants, selectedGuids = [], onSelectGuids } = props

  const displayRestaurants = restaurants.map((restaurant) => {
    const { name, locations } = restaurant

    const displayLocations = locations.map((location) => {
      const { locationName, restaurantName, restaurantGuid: guid } = location

      const checked = selectedGuids.includes(guid)

      const handleChange = () => {
        if (checked) {
          onSelectGuids(without(selectedGuids, guid))
        } else {
          onSelectGuids([...selectedGuids, guid])
        }
      }
      return (
        <Row key={locationName}>
          <Cell>
            <Checkbox
              hideLabel
              checked={checked}
              label={`Select ${restaurantName}`}
              onChange={handleChange}
            />
          </Cell>
          <Cell>{restaurantName}</Cell>
          <Cell>{locationName}</Cell>
          <Cell>{guid}</Cell>
        </Row>
      )
    })

    return (
      <Fragment key={name}>
        <Row variant='sub-section'>
          <HeadingCell colSpan={4}>{name}</HeadingCell>
        </Row>
        {displayLocations}
      </Fragment>
    )
  })

  if (isEmpty(displayRestaurants)) {
    return null
  }

  return (
    <Table>
      <Head>
        <HeadingCell>{''}</HeadingCell>
        <HeadingCell>Restaurant Name</HeadingCell>
        <HeadingCell>Location Name</HeadingCell>
        <HeadingCell>Location Guid</HeadingCell>
      </Head>
      <Body>{displayRestaurants}</Body>
    </Table>
  )
}

import * as React from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { useField } from 'formik'

type Props = {
  fieldName: string
  label?: string
  placeholder?: string
  invalid?: boolean
  helperText?: string
  errorText?: string
  parseValue?: (str: string) => string
}

const DEFAULT_PARSE_VALUE = (value: string) => value

export const FormInput = (props: Props) => {
  const {
    fieldName,
    label: passedLabel,
    invalid: passedInvalid,
    helperText,
    placeholder,
    errorText: passedErrorText,
    parseValue = DEFAULT_PARSE_VALUE
  } = props

  const [, meta, helpers] = useField(fieldName)

  const errorText = passedErrorText || (meta?.touched ? meta?.error : undefined)

  const invalid = passedInvalid ?? !!errorText

  const label = passedLabel ?? fieldName

  return (
    <TextInput
      name={`create-company-from-${fieldName}`}
      label={label}
      placeholder={placeholder}
      value={meta.value || ''}
      invalid={invalid}
      helperText={helperText}
      errorText={errorText}
      onChange={(e: any) => {
        helpers.setValue(parseValue(e.target.value))
      }}
    />
  )
}

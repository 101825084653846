import { useMutation } from '@tanstack/react-query'
import { apiCall } from '../helpers'
import map from 'lodash/map'
import { Location } from '../types'

type UserPayload = {
  hashId: null
  hashEmployeeId: null
  firstName: string
  lastName: string
  email: string
  link: null
  confirmEmail: string
  uuid: null
  _dirty: false
  _new: true
}

type CompanyPayload = {
  companyCode: string
  companyName: string
  isStarter: '1' | '0'
  uuid: null
  oneCampaign: boolean
}

type LocationPayload = {
  restaurantGuid: string
  restaurantName: string
  locationName: string
  _selected: true
}

type Payload = {
  users: [UserPayload]
  company: CompanyPayload
  locations: LocationPayload[]
}

type Variables = {
  firstName: string
  lastName: string
  email: string
  companyCode: string
  companyName: string
  isStarter: boolean
  oneCampaign: boolean
  locations: Location[]
}

const getPayload = (variables: Variables): Payload => {
  const {
    firstName,
    lastName,
    email,
    companyCode,
    companyName,
    isStarter,
    oneCampaign,
    locations
  } = variables

  return {
    users: [
      {
        hashId: null,
        hashEmployeeId: null,
        firstName,
        lastName,
        email,
        link: null,
        confirmEmail: email,
        uuid: null,
        _dirty: false,
        _new: true
      }
    ],
    company: {
      companyCode,
      companyName,
      isStarter: isStarter ? '1' : '0',
      uuid: null,
      oneCampaign: oneCampaign
    },
    locations: map(locations, (location) => {
      const { restaurantGuid, restaurantName, locationName } = location

      return {
        restaurantGuid,
        restaurantName,
        locationName,
        _selected: true
      }
    })
  }
}

type Args = {
  viewCompanyCode: string
}

type Response = {
  obj: null
  errorCode: 0
  id: 0
  hashID: null
  hasError: false
  hasWarning: false
  successMessage: string
  sMessage: string
  warningMessage: string
  wMessage: string
  errorMessage: null
  eMessage: null
  redirectURL: string
  url: string
  isStatic: false
  operationType: 0
  moduleName: null
  guid: string
}

export const useStartImplementationMutation = (args: Args) => {
  const { viewCompanyCode } = args

  return useMutation({
    mutationFn: async (variables: Variables) => {
      const resp = await apiCall<Response>({
        url: `/mvc/${viewCompanyCode}/Company/Implementation/StartImplementation`,
        method: 'POST',
        body: getPayload(variables)
      })

      if (resp.hasError) {
        throw resp
      }
      return resp
    }
  })
}

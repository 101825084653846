import pickBy from 'lodash/pickBy'

type Args<T = any> = {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  headers?: Record<string, string | null>
  body?: string | T
}

declare function $(str: string): any

const getToken = () => {
  try {
    return $(
      '#__ECAjaxAntiForgeryForm input[name="__RequestVerificationToken"]'
    ).val()
  } catch {
    return ''
  }
}

const DEFAULT_HEADERS = {
  Accept: 'application/json'
}

const getHeaders = (isFormData: boolean) => {
  if (isFormData) {
    return DEFAULT_HEADERS
  }
  return {
    ...DEFAULT_HEADERS,
    'Content-Type': 'application/json'
  }
}

export const apiCall = async <T = any>(args: Args) => {
  const { url, method = 'GET', headers, body = '' } = args

  const token = getToken()

  const isFormData = body instanceof FormData || body instanceof URLSearchParams

  const parsedBody =
    typeof body === 'string' || isFormData
      ? body
      : !body
      ? null
      : JSON.stringify(body)

  const fullHeaders = pickBy({ ...getHeaders(isFormData), ...headers })

  const options = {
    method,
    headers: fullHeaders,
    ...(token ? { __ECRequestVerificationToken: token } : undefined),
    ...(parsedBody !== null ? { body: parsedBody } : undefined)
  } as const

  const fetchResp = await fetch(url, options)

  if (fetchResp.ok) {
    const resp = (await fetchResp.json()) as T

    return resp
  }

  throw new Error(fetchResp.statusText || 'Unknown Error')
}

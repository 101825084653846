import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { Error500DesktopIllustration } from '@toasttab/buffet-pui-illustrations'

import { PortalProvider } from '@toasttab/ec-layout'

const queryClient = new QueryClient()

type Props = React.PropsWithChildren<{}>

export const AppProviders = ({ children }: Props) => {
  return (
    <ErrorBoundary fallback={<Error500DesktopIllustration />}>
      <React.Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <SnackBarProvider>
            <PortalProvider value='data-impl'>{children}</PortalProvider>
            <ReactQueryDevtools />
          </SnackBarProvider>
        </QueryClientProvider>
      </React.Suspense>
    </ErrorBoundary>
  )
}

import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { CreateCompanyPage, StartImplementationPage } from '../pages'

const router = createBrowserRouter([
  {
    path: '/mvc/:companyCode/Company/Implementation/StartImplementation',
    element: <StartImplementationPage />
  },
  {
    path: '/:companyCode/implementation/create-company',
    element: <CreateCompanyPage />
  },
  {
    path: '*',
    element: <></>
  }
])

export const AppRouter = () => <RouterProvider router={router} />

import * as React from 'react'
import { Select } from '@toasttab/buffet-pui-dropdowns'
import { useField } from 'formik'

type Option = {
  label: string
  value: string
}

type Props = {
  fieldName: string
  label?: string
  options: Option[]
  invalid?: boolean
  helperText?: string
  errorText?: string
  parseValue?: (str: string) => string
}

export const FormSelect = (props: Props) => {
  const {
    fieldName,
    label: passedLabel,
    invalid: passedInvalid,
    helperText,
    options,
    errorText: passedErrorText
  } = props

  const [, meta, helpers] = useField(fieldName)

  const errorText = passedErrorText || (meta?.touched ? meta?.error : undefined)

  const invalid = passedInvalid ?? !!errorText

  const label = passedLabel ?? fieldName

  return (
    <Select
      name={`create-company-from-${fieldName}`}
      label={label}
      value={meta.value || ''}
      options={options}
      invalid={invalid}
      helperText={helperText}
      errorText={errorText}
      onChange={(value: string) => helpers.setValue(value)}
    />
  )
}

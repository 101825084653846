import { useQuery } from '@tanstack/react-query'
import { apiCall } from '../helpers'

const HEADERS = {
  Accept: 'application/json, text/javascript, */*; q=0.01',
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': null
}

const getPayload = (companyCode: string) => {
  return new URLSearchParams({ companyCode })
}

type Args = {
  viewCompanyCode: string
  newCompanyCode: string
}

type Response = {
  valid: boolean
}

export const useStartImplementationVerifyCompanyQuery = (args: Args) => {
  const { viewCompanyCode, newCompanyCode } = args

  return useQuery({
    queryFn: async () => {
      if (!newCompanyCode) throw new Error('company code is required')

      try {
        const resp = await apiCall<Response>({
          url: `/mvc/${viewCompanyCode}/Company/Implementation/StartImplementationVerifyCompany`,
          method: 'POST',
          headers: HEADERS,
          body: getPayload(newCompanyCode)
        })
        return {
          newCompanyCode,
          isValid: resp.valid
        }
      } catch {
        return {
          newCompanyCode,
          isValid: undefined
        }
      }
    },

    queryKey: ['company', viewCompanyCode, 'newCode', newCompanyCode],

    enabled: !!newCompanyCode
  })
}

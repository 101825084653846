import * as React from 'react'
import { CardSelector } from '@toasttab/buffet-pui-card-selector'

type Props = {
  title?: string
  subtitle?: string
  isSelected: boolean
  onSelect: () => void
}

const SelectorOption = (props: Props) => {
  const { title, subtitle, isSelected, onSelect } = props

  return (
    <CardSelector
      value={title}
      name={title}
      checked={isSelected}
      // disabled={disabled}
      onChange={() => onSelect()}
    >
      <div className='font-semibold'>{title}</div>
      {subtitle && <div className='text-muted font-normal'>{subtitle}</div>}
    </CardSelector>
  )
}

export default SelectorOption

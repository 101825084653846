import * as React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFeature } from '@toasttab/ec-features'
import CreateCompanyForm from './CreateCompanyForm'

export const CreateCompanyPage = () => {
  const hasNewCreatePage = useFeature('ec-platform-create-company-page')

  const { companyCode } = useParams()

  useEffect(() => {
    const el = document.getElementById('page-legacy-body')
    if (el) el.innerHTML = ''
  }, [])

  if (!hasNewCreatePage && companyCode) {
    const oldUrl = `/mvc/${companyCode}/Company/Implementation/StartImplementation`
    return (
      <div>
        <a href={oldUrl}>Return to old page</a>
      </div>
    )
  }

  if (!companyCode) {
    return <div>Error: missing company code</div>
  }

  return <CreateCompanyForm companyCode={companyCode} />
}

import { useState, useEffect } from 'react'

export function useDebounceValue<T>(value: T, delay: number = 250): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), delay)
    return () => {
      clearTimeout(id)
    }
  }, [value, setDebouncedValue, delay])

  return debouncedValue
}

import * as React from 'react'
import { useState, useMemo } from 'react'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import flatten from 'lodash/flatten'
import compact from 'lodash/compact'
import defer from 'lodash/defer'
import { useFormik, FormikProvider } from 'formik'
import {
  useStartImplementationInitializeQuery,
  useStartImplementationVerifyCompanyQuery,
  useStartImplementationMutation
} from '../../data'
import { parseError } from '../../helpers'
import { useDebounceValue } from '../../hooks'
import {
  FormInput,
  FormSelect,
  RestaurantSelect,
  LocationsTable
} from '../../components'
import * as Yup from 'yup'
import FormSection from './FormSection'
import SelectorOption from './SelectorOption'
import CreateCompanyTopBar from './CreateCompanyTopBar'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

const parseCompanyCode = (str: string) => {
  return str.replace(/[^a-z0-9]/gi, '').toLowerCase()
}

type Props = {
  companyCode: string
}

type State = {
  kind: string
  oneCampaign: string
  companyCode: string
  companyName: string
  firstName: string
  lastName: string
  email: string
  locationGuids: string[]
}

const ONE_CAMPAIGN_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]

const schema = Yup.object({
  kind: Yup.string().oneOf(['starter', 'essential']),
  oneCampaign: Yup.string().oneOf(['yes', 'no']),
  companyCode: Yup.string().required('Company code is required'),
  companyName: Yup.string().required('Company name is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email().required('A valid email is required'),
  locationGuids: Yup.array().of(Yup.string())
})

const CreateCompanyForm = (props: Props) => {
  const { companyCode } = props

  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const initQuery = useStartImplementationInitializeQuery({ companyCode })

  const list = initQuery.data

  const allRestaurantNames = useMemo(() => map(list, 'name'), [list])

  const restaurantsByName = useMemo(() => keyBy(list, 'name'), [list])

  const [names, setNames] = useState<string[]>([])

  const selectedRestaurants = useMemo(() => {
    return map(names, (name) => restaurantsByName[name])
  }, [names, restaurantsByName])

  const locationsByGuid = useMemo(() => {
    const allSelectedLocations = flatten(map(selectedRestaurants, 'locations'))
    return keyBy(allSelectedLocations, 'restaurantGuid')
  }, [selectedRestaurants])

  const mutation = useStartImplementationMutation({
    viewCompanyCode: companyCode
  })

  const formik = useFormik<State>({
    validationSchema: schema,
    initialValues: {
      kind: 'starter',
      oneCampaign: 'no',
      companyCode: '',
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      locationGuids: []
    },
    onSubmit: async (data) => {
      const {
        kind,
        oneCampaign,
        companyCode,
        companyName,
        firstName,
        lastName,
        email,
        locationGuids
      } = data

      const locations = compact(
        map(locationGuids, (guid) => locationsByGuid[guid])
      )

      const variables = {
        firstName,
        lastName,
        email,
        companyCode,
        companyName,
        isStarter: kind === 'starter',
        oneCampaign: oneCampaign === 'yes',
        locations
      }

      try {
        const resp = await mutation.mutateAsync(variables)
        const { redirectURL } = resp

        showSuccessSnackBar('Created company')

        if (redirectURL) {
          defer(() => {
            window.location.href = redirectURL
          })
        }

        return resp
      } catch (error) {
        showErrorSnackBar(parseError(error))
      }
    }
  })

  const newCode = formik.values.companyCode

  const checkCode = useDebounceValue(newCode)

  const codeQuery = useStartImplementationVerifyCompanyQuery({
    viewCompanyCode: companyCode,
    newCompanyCode: checkCode
  })

  const codeIsLoading =
    newCode &&
    (codeQuery.isFetching ||
      !(codeQuery.data?.newCompanyCode === formik.values.companyCode))

  const codeIsValid =
    codeQuery.data?.newCompanyCode === formik.values.companyCode &&
    codeQuery.data?.isValid

  const codeMsg = codeIsLoading
    ? 'Checking...'
    : !!(newCode && codeIsValid === true)
    ? 'Available'
    : undefined

  const codeErrorMsg =
    newCode && !codeIsLoading && codeIsValid === false
      ? 'Code is already in use'
      : undefined

  return (
    <>
      <CreateCompanyTopBar
        onSend={() => formik.handleSubmit()}
        canSend={codeIsValid !== false && !mutation.data}
        isSending={mutation.isLoading}
      />
      <FormikProvider value={formik}>
        <FormSection title='Company Information'>
          <FormSection.Split
            left={
              <SelectorOption
                title='Starter'
                subtitle='Payroll, Team Management'
                isSelected={formik.values.kind === 'starter'}
                onSelect={() => formik.setFieldValue('kind', 'starter')}
              />
            }
            right={
              <SelectorOption
                title='Essential'
                subtitle='Payroll, Team Management, Benefits'
                isSelected={formik.values.kind === 'essential'}
                onSelect={() => formik.setFieldValue('kind', 'essential')}
              />
            }
          />

          <FormSection.Split
            left={
              <FormInput
                label='Company Code'
                fieldName='companyCode'
                placeholder='ex: mrpickles'
                helperText={codeMsg}
                errorText={codeErrorMsg}
                parseValue={parseCompanyCode}
              />
            }
            right={
              <FormInput
                label='Company Name'
                fieldName='companyName'
                placeholder='Mr. Pickles Sandwich Shop'
              />
            }
          />
          <FormSection.Split
            left={
              <FormSelect
                label='1/1 Campaign'
                fieldName='oneCampaign'
                options={ONE_CAMPAIGN_OPTIONS}
                helperText='Refers to a brand new calendar year customer'
              />
            }
          />
        </FormSection>

        <FormSection title='Contact Information'>
          <FormSection.Split
            left={
              <FormInput
                label='First name'
                fieldName='firstName'
                placeholder='Bob'
              />
            }
            right={
              <FormInput
                label='Last Name'
                fieldName='lastName'
                placeholder='Roberts'
              />
            }
          />

          <FormSection.Split
            left={
              <FormInput
                label='Email'
                fieldName='email'
                placeholder='bob@example.com'
              />
            }
          />
        </FormSection>

        <FormSection title='Select Restaurants' isLoading={initQuery.isLoading}>
          <RestaurantSelect
            restaurantNames={allRestaurantNames}
            selectedRestaurantNames={names}
            onChange={(names) => setNames(names)}
          />

          <LocationsTable
            restaurants={selectedRestaurants}
            selectedGuids={formik.values.locationGuids}
            onSelectGuids={(list) => {
              formik.setFieldValue('locationGuids', list)
            }}
          />
        </FormSection>
      </FormikProvider>
    </>
  )
}

export default CreateCompanyForm

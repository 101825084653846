import * as React from 'react'
import { LinkButton, Button } from '@toasttab/buffet-pui-buttons'
import {
  OrderIcon,
  ReportsIcon,
  AutorenewIcon
} from '@toasttab/buffet-pui-icons'
import { HeadingGroup, Title } from '@toasttab/buffet-pui-config-templates'

type Props = {
  onSend: () => void
  canSend: boolean
  isSending?: boolean
}

const CreateCompanyTopBar = (props: Props) => {
  const { onSend, canSend, isSending } = props

  const icon = isSending ? (
    <AutorenewIcon className='animate-spin' accessibility='decorative' />
  ) : (
    <OrderIcon accessibility='decorative' />
  )

  return (
    <div className='flex flex-row items-center justify-between py-8'>
      <div className='inline-block'>
        <HeadingGroup subTitle='Implementation'>
          <Title>Select restaurants for Toast Payroll setup</Title>
        </HeadingGroup>
      </div>

      <div className='inline-block'>
        <LinkButton
          iconLeft={<ReportsIcon />}
          variant='secondary'
          className='mr-8'
          href='/__rid/admin'
          target='_blank'
        >
          Status Dashboard
        </LinkButton>

        <Button
          disabled={!canSend || isSending}
          iconLeft={icon}
          onClick={onSend}
        >
          {isSending ? 'Sending…' : 'Send'}
        </Button>
      </div>
    </div>
  )
}

export default CreateCompanyTopBar

import { useQuery } from '@tanstack/react-query'
import { apiCall, formatName } from '../helpers'
import type { Restaurant } from '../types'
import sortBy from 'lodash/sortBy'

type User = unknown

type Resp = {
  obj: {
    users: User[]
    addUser: {
      hashId: string | null
      hashEmployeeId: string | null
      firstName: string | null
      lastName: string | null
      email: string | null
      link: string | null
      confirmEmail: string | null
      uuid: string | null
      _dirty: boolean
      _new: boolean
    }
    companyInfo: {
      companyCode: string | null
      companyName: string | null
      isStarter: boolean | null
      uuid: string | null
      oneCampaign: boolean
    }
    restaurants: Restaurant[]
    selectedUser: {
      hashId: string | null
      hashEmployeeId: string | null
      firstName: string | null
      lastName: string | null
      email: string | null
      link: string | null
      confirmEmail: string | null
      uuid: string | null
      _dirty: boolean
      _new: boolean
    }
    _initialized: boolean
  }
  errorCode: number
  id: number
  hashID: string | null
  hasError: boolean
  hasWarning: boolean
  successMessage: string | null
  sMessage: string | null
  warningMessage: string
  wMessage: string
  errorMessage: string | null
  eMessage: string | null
  redirectURL: string | null
  url: string | null
  isStatic: boolean
  operationType: number
  moduleName: string | null
  guid: string
}

type Args = {
  companyCode: string | undefined
}

export const useStartImplementationInitializeQuery = (args: Args) => {
  const { companyCode } = args

  return useQuery({
    queryKey: ['companies', companyCode, 'StartImplementationInitialize'],
    queryFn: async () => {
      const resp = await apiCall<Resp>({
        method: 'POST',
        url: `/mvc/${companyCode}/Company/Implementation/StartImplementationInitialize`,
        body: {}
      })

      const restaurants = resp.obj.restaurants

      const formattedRestaurants = restaurants.map((restaurant) => {
        return { ...restaurant, name: formatName(restaurant.name) }
      })

      return sortBy(formattedRestaurants, (restaurant) => restaurant.name)
    },
    enabled: !!companyCode
  })
}
